@iti: iti;
@iti__flag-container: iti__flag-container;
.input-group {
  label:not(.unlabel){
    z-index: 1;
  }
  .@{iti} {
    width: 100%;
    .margin(b, @baseFontPx,0);
    > input {
      .padding(t, @baseFontPx,20);
    }
    &.iti--allow-dropdown {
      .@{iti__flag-container} {
        .padding(t, @baseFontPx,14);
      }

    }
    &.iti--separate-dial-code {
      .iti__selected-flag {
        background-color: transparent;
        outline: none;
      }
    }
  }
}
.ext-phone {
  .work-num-container {
    .padding(l, @baseFontPx,0);
  }
  .input-container {
    &.invalid{
      .col{
        .margin(b, @baseFontPx,0);
      }
    }
  }
}
.phone-field-container{
  &.row {
    position: relative;
    .margin(t, @baseFontPx,0);
    .margin(l, @baseFontPx,0);
  }
}

.iti__flag {
  background-image: url("../images/flags.png");
}

@media not all, only screen and (min--moz-device-pixel-ratio: 2), not all, not all, only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti__flag {
    background-image: url("../images/flags@2x.png");
  }
}

@media only screen and (max-width: 540px) and (min-width: 0px) {
  .work-num-container {
    .ext-num {
      padding-top:.8em;
    }
  }
}

