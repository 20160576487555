// NOTE: Avoid code-duplication by checking that UITK doesn't already have what you need and by using the mixins (see Doc Site -> Base -> Mixins)
@import "../../../../../node_modules/@egencia/uitk-global-styles/mixins/mixin.less"; // DO NOT REMOVE - this proxy is how you're able to get access to UITK's mixins


@import 'intlTelInput.less';
@import 'phone.less';
@colorDisambig: #576267; // TODO: Update with UDS
@globalPageCol1Width: 429px;
@widthFull: 100%;
@color3: #929faa; // TODO: Update with UDS
@loginBoxPadding: 2em;

.auth-content-wrapper {
	.max-width(@baseFontPx, 400);
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	.box-padded {
	  padding: @loginBoxPadding;
	}
}

.auth-header-wrapper {
	.auth-content-wrapper();
	padding: @loginBoxPadding;
}

.site-navigation .auth-nav-item.last {
    text-align: right;
    float: right;
    z-index: 400;
}

.site-navigation .auth-nav-item {
    flex: none;
    font-size: .87rem;
    padding: 1.38em .92em;
    display: block;
    line-height: 1.45;
    text-decoration: none;
    color: @colorGrayscale4;
}

#global-header .site-navigation #auth-header-menu {
  order: 4;
}

.primary-nav .ul-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.auth-header-wrapper-fullwidth {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	padding: @loginBoxPadding;
}

.auth-ad-wrapper {
	.auth-content-wrapper;
	.margin(t, @baseFontPx, 48);
}
.my-header-div {
	background: #152835; // TODO: Update with UDS
	width: @widthFull;
}

.egencia-login-page, .forgot-pass, .sso-search {
	.btn {
		.margin(b, @baseFontPx, 12);
		width: @widthFull;
	}
}
#gam-ad {
	text-align: center;

	img {
		width: 100%;
		max-width: 350px;
	}
}

#login-page {

	#password {
    	padding-right: 13%;

    	&::-ms-clear, &::-ms-reveal {
    		display: none;
		}
	}

	#password-visibility-toggle,#reenter-password-visibility-toggle {
		font-size: large;
    	padding-bottom: 0.4em;
    	padding-left: 0.2em;
    	padding-right: 0.2em;
	}


	// honeypot
	.hp-field {
		display: none;
	}

}

#reset-password-page{
	#password-visibility-toggle,#reenter-password-visibility-toggle {
		font-size: large;
		padding-bottom: 0.4em;
		padding-left: 0.2em;
		padding-right: 0.2em;
	}
	.btn{
		width:@widthFull;
	}
}

.egencia-login-page,
.forgot-pass {
  p {
    .margin(b, @baseFontPx, 18);
  }
}

.sso-details {
	.inner-col {
		margin: auto;
		span {
			font-size: 13px;
		}
		button {
		 & > span {
				@media only screen and (min-width: 992px) and (max-width: 1100px) {
				    padding:1.27em 1.75em 1.27em;
				}
				@media only screen and (min-width: 769px) and (max-width: 991px) {
				    padding:1.27em 0.8em 1.27em;
				}
			}
			@media only screen and (max-width: 768px) {
				margin-top: 2em;
    			width: 80%;
			}

		}

		div#country-content {
			display: flex;
    		align-items: center;

    		@media only screen and (max-width: 768px) {
						display: block;
						margin-bottom: 2em;
			}

    		span {
    			    text-align: left;
    				margin-left: 1em;
    				font-size: 12px;
    		}

    		uitk-country-flag {
				zoom: 1.5;
	    		min-width: 16px;
	    		vertical-align: middle;
			}
		}
	}

	#sso-details-back {
		i {
			font-size: 14px;
		}
	}

	div.nested-grid + div.nested-grid {
		@media only screen and (max-width: 540px) {
			margin-top: 0.5em;
		}

	}



}

.sso-search {
	#error-message {
		clear: left;
	}
}

#sso-disabled-message-container {
	@media only screen and (min-width: 768px) {
	    width: 70%;
	}
    margin: auto;
    text-align:left;
}

.forgot-pass {
	.max-width(@baseFontPx, 400);
     min-height: 408px;
}
.new-forgot-container{
	height: 492px;
}

.btn.disambig {
	margin-right: 0px;
}

.btn.reset-password-button {
	margin-right: 200px;
}

.disambigDataContainer {
	background-color: rgba(255, 255, 255, 0.9); // TODO: Update with UDS
	border-radius: 0.2em 0.2em 0.2em 0.2em;
	margin: 0 auto;
	padding: 12px;
	width: 70%;
}

@media only screen and (max-width: 768px) {
	.admin-disambig-data-container {
		&:extend(.disambigDataContainer);
		width: 100% !important;
	}
	.admin-tool-td {
		text-align: center !important;
	}
}
.admin-disambig-data-container {
	&:extend(.disambigDataContainer);
	width: 50%;
}
.disambigHeader {
	font-size: 1.0em;
	font-weight: 500;
	padding-top: 0.5em;
}
.disambigText {
	color: @colorDisambig; // TODO: Update with UDS
	font-size: 0.8em;
	margin-bottom: 1.5em;
}
.disambigTableBorder {
	border: 2px solid transparent;
	width: @widthFull;
}
.disambigTableHeaders {
	background-color: LightGray; // TODO: Update with UDS
	color: @colorDisambig;
	font-weight: 800;
	vertical_align: middle;
}

.disambigActionBtnContainer{
	display: flex;
	flex-direction: row-reverse;
}

.disambig-select {
	vertical-align: middle;
	text-align:center;
}
.admin-tool-table-headers {
	&:extend(.disambigTableHeaders);
	width:180px;
	vertical_align: middle;
}

.vertical-align-middle {
	vertical-align: middle;
}
.admin-tool-select {
	vertical-align: middle;
	padding:8px;
	text-align: right;
}
.admin-tool-select {
	font-weight: 500;
	font-size: 14px;
	margin-right: 6px;
}
.admin-tool-div {
	margin: 0 auto;
	max-width: 1172px;
	padding-right: 1.411764705882353em;
	padding: 2em 1.41176em;
	width: 100%;
}
.welcome-page-container {
	margin-left: 24px;
}
.label.auth-label {
	padding-left: 0px;
	position: relative;
}
.col.my-col.global-page-first-column {
	width: @globalPageCol1Width;
}
.col.my-col.global-page-second-column {
	max-width: @globalPageCol1Width;
}
span.label.reset-password-label {
	padding-left: 0px;
	position: relative;
}
span.label.reset-password-message {
	padding-left: 24px;
	position: relative;
}
td.disambigTableValues {
	vertical-align: middle;
}
.resetPasswordSuccessDiv {
	margin-left: 24px;
}
.reset-message {
	padding-left: 1.5em;
	display: inline-block;
	width: 100%;
}
.no-text-transform {
	text-transform: none !important;
}
.passwordPolicyDetails {
	margin-left: 24px;
}
.welcome-page-language-list {
	list-style-type: none;
}
.welcome-page-language-select {
	margin-bottom: 12px;
}

#sso-logout-vanity-link {
    margin-top: 2.7em;
}

#admin-tool-secondary-header {
	background-color: white;
	padding-left: 96px;
    padding-top: 6px;
    padding-right: 24px;
}

.admin-tool-header {
	.admin-tool-select();
	font-size: .73333333rem !important;
	text-transform: uppercase;
	text-decoration: none !important;
	color: @colorSlateMed !important;
}

.admin-table-heading {
    font-weight: 500;
    font-size: 12px;
}

#admin-name {
    text-transform: capitalize;
    padding-top: 8px;
}

#selected-language {
    text-transform: capitalize;
    padding-top: 5px;
    font-size: 1rem;
}

.text-decoration-none {
	text-decoration: none !important;
}

#esiModal-subHeading {
	margin-bottom: 12px;
}

.policy-violation {
	color: red; // TODO: Update with UDS
}

 #customized-nucaptcha-inner-wrapper {
     margin-left: 0px;
     margin-top: 1.2em;
}
 #nucaptcha-media-wrapper {
	padding-left: 0px;
    border: 1px solid @colorGrayscale4;
    border-radius: .15em;
    box-sizing: content-box;
    float: left;
    width: 281px;
    height: 60px;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 410px) {
    	width: 85%;
    	img.nucaptcha-media {
    		width: 100%;
    	}
    }

    @media only screen and (max-width: 300px) {
    	width: 80%;
    	img.nucaptcha-media {
    		width: 100%;
    	}
    }

    #captcha-progress-container+#media-container {
    	width: 0px;
    }
    #captcha-progress-container.hidden+#media-container {
    	width: 100%;
    }

    #captcha-progress-container {
	    display: flex;
	    align-items: center;
	    width: 100%;
    }

    #audio-listen-again {
    	display: none;
    }

    #audio-replay {

	    width: 30px;
	    height: 30px;
		overflow: hidden;
		font-size: 0;
    	text-align: center;
    	margin-left: 38px;
    	margin-right: 8px;
    	cursor: pointer;

    	@media only screen and (max-width: 410px) {
    		margin-left: 10px;
    	}

		&::before {
		        content: "\e099";
			    font-family: 'EgenciaIcons';
			    display: inline-block;
			    position: relative;
			    font-weight: normal;
			    line-height: 30px;
			    text-align: center;
				speak: none;
				font-size: 24px;
				//width: 30px;
			}

		&:hover {
			background-position: 192px -64px;
			color: @colorBlueMed;
		}
		&:active {
			background-position: 128px -64px;
			color: @colorBlueMed;
		}
	}

	// hide download link
	#audio-download {
 		display: none;
	}

    #captcha-progress-bar {
   		margin-right: 38px;
   		@media only screen and (max-width: 410px) {
    		margin-right: 10px;
    	}
    }
}
 #customized-nucaptcha-answer-wrapper {
 	margin-top: 0.75em;
 	margin-bottom: 1em;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
}
/* NuCaptcha styles */
 #nucaptcha-answer {
 	// from uitk input class
 	display: inline-block;
    height: 3.69em;
    border: 1px solid @colorGrayscale4;
    border-radius: .15em;
    background-color: white;
    font-size: .87rem;
    padding: .69em .69em;
    outline: 0;
    color: #152835; // TODO: Update with UDS

    float: right;
    width: 40%;
    overflow: hidden;

    &:focus {
      outline: none;
  	  border-color: @colorBlueMed;
  	}
}
 #nucaptcha-button-wrapper {
 	padding-left: 0px;
    margin: auto;
    float: right;
}

 #directions-verbose-label {
	float: left;
    overflow: hidden;
    display: inline-block;
    width: 60%;
    font-size: 13px;
}
 #nucaptcha-refresh-button-wrapper {

 	border: 1px solid @colorGrayscale4;
    border-radius: .15em;
    width: 30px;
    height: 30px;
    cursor: pointer;

	&:hover, &:active {
		border-color: @colorBlueMed;
		color: @colorBlueMed;
	}
	a.cmd {
		display: block;
		overflow: hidden;
		font-size: 0;
    	text-align: center;
	}

	a.cmd::before {
	        content: "\e807";
		    font-family: 'EgenciaIcons';
		    display: inline-block;
		    position: relative;
		    font-weight: normal;
		    line-height: 30px;
		    text-align: center;
		    speak: none;
		    font-size: 16px;
		    width: 30px;
	}

	 a.cmd:hover {
	     background-position: 192px -64px;
	     color: @colorBlueMed;
	}
	 a.cmd:active {
	     background-position: 128px -64px;
	     color: @colorBlueMed;
	}
}

 #nucaptcha-audio-toggle-button-wrapper {

	border: 1px solid @colorGrayscale4;
    border-radius: .15em;
    width: 30px;
    height: 30px;
    margin-top: 4px;
    cursor: pointer;

	&:hover, &:active {
		border-color: @colorBlueMed;
		color: @colorBlueMed;
	}

	a#player-mode-audio.cmd {
		display: block;
		overflow: hidden;
		font-size: 0;
    	text-align: center;
	}

	a#player-mode-audio.cmd::before {
	    content: "\f027";
	    font-family: 'EgenciaIcons';
	    display: inline-block;
		position: relative;
		font-weight: normal;
		line-height: 30px;
		text-align: center;
		speak: none;
		font-size: 16px;
		width: 30px;
	}

	 a.cmd:hover {
	     background-position: 192px -64px;
	     color: @colorBlueMed;
	}
	 a.cmd:active {
	     background-position: 128px -64px;
	     color: @colorBlueMed;
	}

	a#player-mode-visual.cmd {
		display: block;
    	overflow: hidden;
    	font-size: 16px;
    	text-align: center;
    	text-decoration: none;
    	line-height: 30px;
	}
}


// Set global transition vars
@transDuration: 0.25s;
@transDelay: @transDuration;
@transTimeFunc: cubic-bezier(0.250, 0.460, 0.450, 0.940);


.narrow-content {
  max-width: 450px;
  margin: 0 auto;
}

.reset-check {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 100%;
  vertical-align: middle;

	&.defaultSuccess {
		background: #CFD5DB;
		.icon {
			color: white;
			opacity: 1;

			&.icon-close {
				opacity: 0;
			}
		}
	}
  &.success {
    background: @colorGreenMed;
    border-color: @colorGreenMed;

    .icon {
      color: white;
      opacity: 1;

      &.icon-close {
        opacity: 0;
      }
    }
  }
  &.error {
    background: @colorRedMed;
    border-color: transparent;

    .icon {
      opacity: 1;
      color: white;

      &.icon-success {
        opacity: 0;
      }
    }
  }

  .icon {
    position: absolute;
    top: 2px;
    left: 1px;
    opacity: 0;
    transition: opacity @transDuration ease-in-out;
  }
}

.pwd-history-info {
    position: relative;

    &:first-child {
    	margin-bottom: 1.54em;
    }
    &:last-child {
    	margin-top: 1.54em;
    }

    & > .icon {
	    color: #3799cf; // TODO: Update with UDS
	    position: absolute;
	  	top: 0.2em;
	}

	& > .icon + .pwd-history-info-message {
	  left: 2.13em;
	}

	& .pwd-history-info-message {
	  width: 90%;
	  display: inline-block;
	  position: relative;
	}
}

.grayscale5 {
	color: @color3 !important;
}

.center {
	text-align: center;
}

span.reset-check+span {
	position: absolute;
	margin-left: 4px;
}


.site-navigation {
	justify-content: center;
}

#language-menu {
	min-width: auto;
	top: unset;
}

a.language-list {
    background-color: white !important;
    text-align: left;
	color: @colorGrayscale5;
    font-size: 100%;
	padding: .38em 0;
	min-width: 7.5em;
	max-width: 16.88em;
}

a.language-list:hover {
    color: @colorBlueMed !important;
}

#language-selector-menu {
    font-weight: 400;
    font-size: .87rem;
	.tooltip-inner ul{
		margin-top: 0 !important;
	}
}

.language-menu-tooltip {
	display: flex;
	flex-direction: row;
}

.language-menu-icon {
	padding-top: 5px;
}

.language-list-tooltip{
	margin: 0;
	list-style-type: none;
	li::marker{
		content: none;
	}
}

#vanity-redirection-modal-image {
	width: 75%;
	margin-bottom: 2%;
}

#modal-vanity-redirection-modal,#modal-login-to-sso-redirection {
	.icon-close {
		display: none;
	}
}

#vanity-redirection-info-modal-heading {
	display: inline-block;
  	margin-top: 1%;
  	vertical-align: top;
}

#vanity-redirection-info-modal-sub-heading {
	margin-top: 1%;
}

#login-alert-message-container {
	@media only screen and (min-width: 768px) {
		width: 75%;
	}
	margin: auto;
	text-align:left;
}

.reset-password-submit-button-margin-top {
     //margin-top: 1.5em;
}
.caps-alert-warn{
	position: relative;

	.alert-message{
		display:inline-block;
		margin-left: .1em;
	}
	.icon{
		color: @colorWarn;
	}
}
.displayNone{
	display: none !important;
}

.contextual-help-link, #primary-nav-help-center-medium, #primary-nav-help-center-large, #primary-nav-help-center {
	display: none;
}
.contextual-help-link-auth{
	display:block !important;
}
.disabledField{
	background:@colorGrayscale1 !important;
}
.mix-mode-box{
	padding-left: 1.6em;
	padding-bottom: 1.6em;
	padding-right: 1.6em;
}
@media only screen and (min-width: @breakpointScreenSmallMin) and (max-width: @breakpointScreenLargeMin) {
	.mix-mode-box-small-screen {
		margin-top: 1.6em;
	}
}

.forgot-sso-section .reset-message, .forgot-reset-section .reset-message{
	padding: 0.7em;
	background-color: #ECF7FC; // TODO: Update with UDS
}

.forgot-reset-section{
	margin-top: .8em;
}
.mixed-mode-section{
	background-color: #ECF7FC !important; // TODO: Update with UDS
	div{
		padding: 0.8em;
	}
	.sso-account-container{
		span{
			padding-left: 0.5em;
		}
	}
}
.mix-mode-message{
	height:50px;
}

.mixed-mode-container{
	margin-bottom: 0.92em;
	font-size: 0.9em;
}
.forgot-OR-section{
	text-align:center;
	margin-top: 2em;
	margin-bottom: 2em;
}

#sso_reset_btn .btn-label{
	color: #1565b1; // TODO: Update with UDS
}

.backgroundWhite{
	background: #ffffff !important;
}
.sso-login-link{
	font-size: 1em !important;
	cursor: pointer;
}
.sso-login-container{
	padding-top: 0 !important;
}
.user-name-container{
	margin-bottom: 0.46em !important;
}

.authLoginSubmit{
	margin-top: 1.7rem !important;
}

#error-message{
	display:inline;
}

#email{
	color: @colorSlateDark;
	display: inline-block;
	margin-bottom: 0.8em;
}

.insurance-icon{
	width: 64px;
	height: 64px;
	margin-top: -8px;
}



.sub-heading{
	color: #556976;
}

#forgot-text-seperator{
	background: #FFFFFF;
	border: 1px solid @colorGrayscale2;
	box-sizing: border-box;
	border-radius: 8px;
}

#security-question-heading{
	h3{
		font-weight: 500 !important;
	}
	color: #152835;
}

#security-question-button{
	margin-bottom: 2.1em;
}
.forgot-page-heading,.login-page-heading,.reset-page-heading{
	background: #ffffff;
	border-radius: 2px;
	color: @colorSlateDark;
	font-size: 1.5em;
	line-height: 1.7em;
	font-weight: 500;
	margin-bottom: 1.3em;
}

.flex-container{
	display: flex;
	flex-direction: row;
}

.secondary-message {
	display: inline-block;
	margin-bottom: 0.4em;
	color: @colorSlateLight;
	font-size:0.75em;
}

.rowDirection{
	flex-direction: row;
}

.successTitle{
	color:@colorSlateDark;
	font-weight: 500;
	font-size: 1.6em;
	margin-bottom: 6px;
}
.successIcon{
	height: 54px;
	width: 54px;
	background-color: @colorGreenMed;
	border-radius: 50%;
}

.flexWrapNo{
	flex-wrap: nowrap;
}
.flex-container{
	flex-direction: row;
	display: flex;
}

.flex-item-mfa-subHeading {
	flex-grow: 1;
}

#mfa-container{
	min-height: 400px;
}

.message-heading{
	font-size: 0.88em;
	color: @colorSlateLight;
}

.security-question-button{
	margin-top:2em;
	font-size: 0.93em;
	line-height: 1.2em;

}
.spaceDiv{
	height: 44px;
}

.new-password-policy{
	box-sizing: border-box;
	border-radius: 0.5em;
	padding: 2rem;
	position: absolute;
	left:450px;
	.height(@baseFontPx,376);
	.width(@baseFontPx,318);
	bottom: 1.6rem;
	background-color: white;
}

.mobile-password-policy{
	background-color: #F5F6FA;
	.height(@baseFontPx,222);
	width:100%;
}

#pswd-policy-heading{
	width: 88%;
	font-size: 1.125em;
	line-height: 1.3125em;
	color: #354855;
	margin-bottom: 1.5em;
}

#password-recovery-heading{
	color: @colorSlateDark;
	margin-left: 1em;
	text-align: left;
	font-size: 1.5em;
	margin-top: 1.25em;
}

.lightbulb-icon{
	width: 4em;
	height: 4em;
	margin-bottom: 1.125em;
}
.reset-insurance-icon{
	width: 4em;
	height: 4em;
	margin-top: 1.25em;
	margin-right: 0.625em;
}
.policyContainer:after,
.policyContainer:before {
	content: '';
	display: block;
	position: absolute;
	left: -22px;
	width: 0;
	height: 0;
	border-style: solid;
}

.policyContainer:after {
  top: 10px;
  border-color: transparent  transparent transparent  transparent;
  border-width: 10px;
}

.policyContainer:before {
  top: 7em;
  border-color: transparent white  transparent transparent;
  border-width: 11px;
}
.relativeContent{
	position: relative;
}

#userName{
	background-color: white !important;
}

#forgot-link{
	margin-top: 32px;
}

.pswd-policy{
	line-height: 1.5em;
	font-size: 1em;
	width: 75%;
}

.required {
	color: @colorError;
}

#pswd-validate{
	background-color: #F5F6FA !important;
	margin-top: 1em;
	padding: 1em;
	margin-bottom: 1em;
	border:1em;
}

#password-use-num{
	margin-top: 2.6em;
}
#password-use-uppercase{
	padding-top:0.5em;
}

.mobile-policy-list{
	padding:.75em 0 .75em 0
}
.popup-header i{
	line-height: 34px;
	font-size: 20px;
	justify-content: center;
	display: flex;
	align-items: center;
	margin-right: 0.5em;
	color:@colorBlueMed;
}

.popup-header p{
	font-weight: 500;
	font-size: 1.125em;
	line-height: 1.375em;
	margin-bottom: 0.5em;
}
.popup-body {
	margin: 1em 0 1em 0;
}
.popup-date-picker {
	margin-top: 1em;
}

.popup-footer-btn{
	margin-right:1em;
}
.update-mobile-text{
	margin-top: 2.1em;
	margin-bottom: 1.2em;
}


.phone-field{
	width: 300px;
	margin-bottom: 2em;
}

.footer-btn-right{
	justify-content: flex-end !important;
}
.flex-grow{
	flex-grow: 1;
}
.reset-mfa-success-msg{
	font-weight: 500;
	font-size: 1.5em;
	line-height: 1.45;
	width:10.6em;
	color: @colorSlateDark;
	text-align: left;
}
.reset-mfa-success-icon {
	.height(@baseFontPx,54);
	.width(@baseFontPx,54);
	color:white;
	border-radius: 50%;
	justify-content: center;
	display: flex;
	align-items: center;
	background-color: @colorGreenMed;
	.icon{
		font-size: 1.5em;
	}
}
.reset-page-heading-success{
	font-size: 1em;
}

.hiddenUserName{
	height: 0;
	width: 0;
	position: absolute;
	border:none;
}

#language-switcher-title {
	white-space: nowrap;
}

.date-of-birth {
	margin-top: 1.5625em;
}

generic-message-heading{
	list-style-type: circle;
	font-size: 0.88em;
	display: inline-block;
	margin-bottom: 0.4em;
	color: @colorSlateLight;
	::marker{
		content: initial;
	}
}