.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti input,
.iti input[type=text],
.iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}
.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}
@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}
.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}
.iti__country {
  padding: 5px 10px;
  outline: none;
}
.iti__dial-code {
  color: #999;
}
.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti__flag-box,
.iti__country-name,
.iti__dial-code {
  vertical-align: middle;
}
.iti__flag-box,
.iti__country-name {
  margin-right: 6px;
}
.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}
.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}
.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.iti--container:hover {
  cursor: pointer;
}
.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}
.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}
.iti__flag {
  width: 20px;
}
.iti__flag.iti__be {
  width: 18px;
}
.iti__flag.iti__ch {
  width: 15px;
}
.iti__flag.iti__mc {
  width: 19px;
}
.iti__flag.iti__ne {
  width: 18px;
}
.iti__flag.iti__np {
  width: 13px;
}
.iti__flag.iti__va {
  width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}
.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}
.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}
.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}
.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}
.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}
.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}
.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}
.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}
.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}
.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}
.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}
.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}
.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}
.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}
.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}
.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}
.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}
.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}
.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}
.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}
.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}
.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}
.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}
.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}
.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}
.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}
.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}
.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}
.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}
.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png");
  }
}
.iti__flag.iti__np {
  background-color: transparent;
}
.input-group label:not(.unlabel) {
  z-index: 1;
}
.input-group .iti {
  width: 100%;
  margin-bottom: 0;
}
.input-group .iti > input {
  padding-top: 1.25em;
}
.input-group .iti.iti--allow-dropdown .iti__flag-container {
  padding-top: 0.88em;
}
.input-group .iti.iti--separate-dial-code .iti__selected-flag {
  background-color: transparent;
  outline: none;
}
.ext-phone .work-num-container {
  padding-left: 0;
}
.ext-phone .input-container.invalid .col {
  margin-bottom: 0;
}
.phone-field-container.row {
  position: relative;
  margin-top: 0;
  margin-left: 0;
}
.iti__flag {
  background-image: url("../images/flags.png");
}
@media not all, only screen and (min--moz-device-pixel-ratio: 2), not all, not all, only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti__flag {
    background-image: url("../images/flags@2x.png");
  }
}
@media only screen and (max-width: 540px) and (min-width: 0px) {
  .work-num-container .ext-num {
    padding-top: 0.8em;
  }
}
.auth-content-wrapper {
  max-width: 25em;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.auth-content-wrapper .box-padded {
  padding: 2em;
}
.auth-header-wrapper {
  max-width: 25em;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 2em;
}
.auth-header-wrapper .box-padded {
  padding: 2em;
}
.site-navigation .auth-nav-item.last {
  text-align: right;
  float: right;
  z-index: 400;
}
.site-navigation .auth-nav-item {
  flex: none;
  font-size: 0.87rem;
  padding: 1.38em 0.92em;
  display: block;
  line-height: 1.45;
  text-decoration: none;
  color: #42525d;
}
#global-header .site-navigation #auth-header-menu {
  order: 4;
}
.primary-nav .ul-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.auth-header-wrapper-fullwidth {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 2em;
}
.auth-ad-wrapper {
  max-width: 25em;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  margin-top: 3em;
}
.auth-ad-wrapper .box-padded {
  padding: 2em;
}
.my-header-div {
  background: #152835;
  width: 100%;
}
.egencia-login-page .btn,
.forgot-pass .btn,
.sso-search .btn {
  margin-bottom: 0.75em;
  width: 100%;
}
#gam-ad {
  text-align: center;
}
#gam-ad img {
  width: 100%;
  max-width: 350px;
}
#login-page #password {
  padding-right: 13%;
}
#login-page #password::-ms-clear,
#login-page #password::-ms-reveal {
  display: none;
}
#login-page #password-visibility-toggle,
#login-page #reenter-password-visibility-toggle {
  font-size: large;
  padding-bottom: 0.4em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}
#login-page .hp-field {
  display: none;
}
#reset-password-page #password-visibility-toggle,
#reset-password-page #reenter-password-visibility-toggle {
  font-size: large;
  padding-bottom: 0.4em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}
#reset-password-page .btn {
  width: 100%;
}
.egencia-login-page p,
.forgot-pass p {
  margin-bottom: 1.13em;
}
.sso-details .inner-col {
  margin: auto;
}
.sso-details .inner-col span {
  font-size: 13px;
}
@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .sso-details .inner-col button > span {
    padding: 1.27em 1.75em 1.27em;
  }
}
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .sso-details .inner-col button > span {
    padding: 1.27em 0.8em 1.27em;
  }
}
@media only screen and (max-width: 768px) {
  .sso-details .inner-col button {
    margin-top: 2em;
    width: 80%;
  }
}
.sso-details .inner-col div#country-content {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .sso-details .inner-col div#country-content {
    display: block;
    margin-bottom: 2em;
  }
}
.sso-details .inner-col div#country-content span {
  text-align: left;
  margin-left: 1em;
  font-size: 12px;
}
.sso-details .inner-col div#country-content uitk-country-flag {
  zoom: 1.5;
  min-width: 16px;
  vertical-align: middle;
}
.sso-details #sso-details-back i {
  font-size: 14px;
}
@media only screen and (max-width: 540px) {
  .sso-details div.nested-grid + div.nested-grid {
    margin-top: 0.5em;
  }
}
.sso-search #error-message {
  clear: left;
}
#sso-disabled-message-container {
  margin: auto;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  #sso-disabled-message-container {
    width: 70%;
  }
}
.forgot-pass {
  max-width: 25em;
  min-height: 408px;
}
.new-forgot-container {
  height: 492px;
}
.btn.disambig {
  margin-right: 0px;
}
.btn.reset-password-button {
  margin-right: 200px;
}
.disambigDataContainer,
.admin-disambig-data-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.2em 0.2em 0.2em 0.2em;
  margin: 0 auto;
  padding: 12px;
  width: 70%;
}
@media only screen and (max-width: 768px) {
  .admin-disambig-data-container {
    width: 100% !important;
  }
  .admin-tool-td {
    text-align: center !important;
  }
}
.admin-disambig-data-container {
  width: 50%;
}
.disambigHeader {
  font-size: 1em;
  font-weight: 500;
  padding-top: 0.5em;
}
.disambigText {
  color: #576267;
  font-size: 0.8em;
  margin-bottom: 1.5em;
}
.disambigTableBorder {
  border: 2px solid transparent;
  width: 100%;
}
.disambigTableHeaders,
.admin-tool-table-headers {
  background-color: LightGray;
  color: #576267;
  font-weight: 800;
  vertical_align: middle;
}
.disambigActionBtnContainer {
  display: flex;
  flex-direction: row-reverse;
}
.disambig-select {
  vertical-align: middle;
  text-align: center;
}
.admin-tool-table-headers {
  width: 180px;
  vertical_align: middle;
}
.vertical-align-middle {
  vertical-align: middle;
}
.admin-tool-select {
  vertical-align: middle;
  padding: 8px;
  text-align: right;
}
.admin-tool-select {
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
}
.admin-tool-div {
  margin: 0 auto;
  max-width: 1172px;
  padding-right: 1.41176471em;
  padding: 2em 1.41176em;
  width: 100%;
}
.welcome-page-container {
  margin-left: 24px;
}
.label.auth-label {
  padding-left: 0px;
  position: relative;
}
.col.my-col.global-page-first-column {
  width: 429px;
}
.col.my-col.global-page-second-column {
  max-width: 429px;
}
span.label.reset-password-label {
  padding-left: 0px;
  position: relative;
}
span.label.reset-password-message {
  padding-left: 24px;
  position: relative;
}
td.disambigTableValues {
  vertical-align: middle;
}
.resetPasswordSuccessDiv {
  margin-left: 24px;
}
.reset-message {
  padding-left: 1.5em;
  display: inline-block;
  width: 100%;
}
.no-text-transform {
  text-transform: none !important;
}
.passwordPolicyDetails {
  margin-left: 24px;
}
.welcome-page-language-list {
  list-style-type: none;
}
.welcome-page-language-select {
  margin-bottom: 12px;
}
#sso-logout-vanity-link {
  margin-top: 2.7em;
}
#admin-tool-secondary-header {
  background-color: white;
  padding-left: 96px;
  padding-top: 6px;
  padding-right: 24px;
}
.admin-tool-header {
  vertical-align: middle;
  padding: 8px;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
  font-size: 0.73333333rem !important;
  text-transform: uppercase;
  text-decoration: none !important;
  color: #152835 !important;
}
.admin-table-heading {
  font-weight: 500;
  font-size: 12px;
}
#admin-name {
  text-transform: capitalize;
  padding-top: 8px;
}
#selected-language {
  text-transform: capitalize;
  padding-top: 5px;
  font-size: 1rem;
}
.text-decoration-none {
  text-decoration: none !important;
}
#esiModal-subHeading {
  margin-bottom: 12px;
}
.policy-violation {
  color: red;
}
#customized-nucaptcha-inner-wrapper {
  margin-left: 0px;
  margin-top: 1.2em;
}
#nucaptcha-media-wrapper {
  padding-left: 0px;
  border: 1px solid #42525d;
  border-radius: 0.15em;
  box-sizing: content-box;
  float: left;
  width: 281px;
  height: 60px;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 410px) {
  #nucaptcha-media-wrapper {
    width: 85%;
  }
  #nucaptcha-media-wrapper img.nucaptcha-media {
    width: 100%;
  }
}
@media only screen and (max-width: 300px) {
  #nucaptcha-media-wrapper {
    width: 80%;
  }
  #nucaptcha-media-wrapper img.nucaptcha-media {
    width: 100%;
  }
}
#nucaptcha-media-wrapper #captcha-progress-container + #media-container {
  width: 0px;
}
#nucaptcha-media-wrapper #captcha-progress-container.hidden + #media-container {
  width: 100%;
}
#nucaptcha-media-wrapper #captcha-progress-container {
  display: flex;
  align-items: center;
  width: 100%;
}
#nucaptcha-media-wrapper #audio-listen-again {
  display: none;
}
#nucaptcha-media-wrapper #audio-replay {
  width: 30px;
  height: 30px;
  overflow: hidden;
  font-size: 0;
  text-align: center;
  margin-left: 38px;
  margin-right: 8px;
  cursor: pointer;
}
@media only screen and (max-width: 410px) {
  #nucaptcha-media-wrapper #audio-replay {
    margin-left: 10px;
  }
}
#nucaptcha-media-wrapper #audio-replay::before {
  content: "\e099";
  font-family: 'EgenciaIcons';
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  speak: none;
  font-size: 24px;
}
#nucaptcha-media-wrapper #audio-replay:hover {
  background-position: 192px -64px;
  color: #006fcf;
}
#nucaptcha-media-wrapper #audio-replay:active {
  background-position: 128px -64px;
  color: #006fcf;
}
#nucaptcha-media-wrapper #audio-download {
  display: none;
}
#nucaptcha-media-wrapper #captcha-progress-bar {
  margin-right: 38px;
}
@media only screen and (max-width: 410px) {
  #nucaptcha-media-wrapper #captcha-progress-bar {
    margin-right: 10px;
  }
}
#customized-nucaptcha-answer-wrapper {
  margin-top: 0.75em;
  margin-bottom: 1em;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
/* NuCaptcha styles */
#nucaptcha-answer {
  display: inline-block;
  height: 3.69em;
  border: 1px solid #42525d;
  border-radius: 0.15em;
  background-color: white;
  font-size: 0.87rem;
  padding: 0.69em 0.69em;
  outline: 0;
  color: #152835;
  float: right;
  width: 40%;
  overflow: hidden;
}
#nucaptcha-answer:focus {
  outline: none;
  border-color: #006fcf;
}
#nucaptcha-button-wrapper {
  padding-left: 0px;
  margin: auto;
  float: right;
}
#directions-verbose-label {
  float: left;
  overflow: hidden;
  display: inline-block;
  width: 60%;
  font-size: 13px;
}
#nucaptcha-refresh-button-wrapper {
  border: 1px solid #42525d;
  border-radius: 0.15em;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
#nucaptcha-refresh-button-wrapper:hover,
#nucaptcha-refresh-button-wrapper:active {
  border-color: #006fcf;
  color: #006fcf;
}
#nucaptcha-refresh-button-wrapper a.cmd {
  display: block;
  overflow: hidden;
  font-size: 0;
  text-align: center;
}
#nucaptcha-refresh-button-wrapper a.cmd::before {
  content: "\e807";
  font-family: 'EgenciaIcons';
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  speak: none;
  font-size: 16px;
  width: 30px;
}
#nucaptcha-refresh-button-wrapper a.cmd:hover {
  background-position: 192px -64px;
  color: #006fcf;
}
#nucaptcha-refresh-button-wrapper a.cmd:active {
  background-position: 128px -64px;
  color: #006fcf;
}
#nucaptcha-audio-toggle-button-wrapper {
  border: 1px solid #42525d;
  border-radius: 0.15em;
  width: 30px;
  height: 30px;
  margin-top: 4px;
  cursor: pointer;
}
#nucaptcha-audio-toggle-button-wrapper:hover,
#nucaptcha-audio-toggle-button-wrapper:active {
  border-color: #006fcf;
  color: #006fcf;
}
#nucaptcha-audio-toggle-button-wrapper a#player-mode-audio.cmd {
  display: block;
  overflow: hidden;
  font-size: 0;
  text-align: center;
}
#nucaptcha-audio-toggle-button-wrapper a#player-mode-audio.cmd::before {
  content: "\f027";
  font-family: 'EgenciaIcons';
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  speak: none;
  font-size: 16px;
  width: 30px;
}
#nucaptcha-audio-toggle-button-wrapper a.cmd:hover {
  background-position: 192px -64px;
  color: #006fcf;
}
#nucaptcha-audio-toggle-button-wrapper a.cmd:active {
  background-position: 128px -64px;
  color: #006fcf;
}
#nucaptcha-audio-toggle-button-wrapper a#player-mode-visual.cmd {
  display: block;
  overflow: hidden;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  line-height: 30px;
}
.narrow-content {
  max-width: 450px;
  margin: 0 auto;
}
.reset-check {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 100%;
  vertical-align: middle;
}
.reset-check.defaultSuccess {
  background: #CFD5DB;
}
.reset-check.defaultSuccess .icon {
  color: white;
  opacity: 1;
}
.reset-check.defaultSuccess .icon.icon-close {
  opacity: 0;
}
.reset-check.success {
  background: #178143;
  border-color: #178143;
}
.reset-check.success .icon {
  color: white;
  opacity: 1;
}
.reset-check.success .icon.icon-close {
  opacity: 0;
}
.reset-check.error {
  background: #c6261e;
  border-color: transparent;
}
.reset-check.error .icon {
  opacity: 1;
  color: white;
}
.reset-check.error .icon.icon-success {
  opacity: 0;
}
.reset-check .icon {
  position: absolute;
  top: 2px;
  left: 1px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.pwd-history-info {
  position: relative;
}
.pwd-history-info:first-child {
  margin-bottom: 1.54em;
}
.pwd-history-info:last-child {
  margin-top: 1.54em;
}
.pwd-history-info > .icon {
  color: #3799cf;
  position: absolute;
  top: 0.2em;
}
.pwd-history-info > .icon + .pwd-history-info-message {
  left: 2.13em;
}
.pwd-history-info .pwd-history-info-message {
  width: 90%;
  display: inline-block;
  position: relative;
}
.grayscale5 {
  color: #929faa !important;
}
.center {
  text-align: center;
}
span.reset-check + span {
  position: absolute;
  margin-left: 4px;
}
.site-navigation {
  justify-content: center;
}
#language-menu {
  min-width: auto;
  top: unset;
}
a.language-list {
  background-color: white !important;
  text-align: left;
  color: #303f49;
  font-size: 100%;
  padding: 0.38em 0;
  min-width: 7.5em;
  max-width: 16.88em;
}
a.language-list:hover {
  color: #006fcf !important;
}
#language-selector-menu {
  font-weight: 400;
  font-size: 0.87rem;
}
#language-selector-menu .tooltip-inner ul {
  margin-top: 0 !important;
}
.language-menu-tooltip {
  display: flex;
  flex-direction: row;
}
.language-menu-icon {
  padding-top: 5px;
}
.language-list-tooltip {
  margin: 0;
  list-style-type: none;
}
.language-list-tooltip li::marker {
  content: none;
}
#vanity-redirection-modal-image {
  width: 75%;
  margin-bottom: 2%;
}
#modal-vanity-redirection-modal .icon-close,
#modal-login-to-sso-redirection .icon-close {
  display: none;
}
#vanity-redirection-info-modal-heading {
  display: inline-block;
  margin-top: 1%;
  vertical-align: top;
}
#vanity-redirection-info-modal-sub-heading {
  margin-top: 1%;
}
#login-alert-message-container {
  margin: auto;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  #login-alert-message-container {
    width: 75%;
  }
}
.caps-alert-warn {
  position: relative;
}
.caps-alert-warn .alert-message {
  display: inline-block;
  margin-left: 0.1em;
}
.caps-alert-warn .icon {
  color: #303f49;
}
.displayNone {
  display: none !important;
}
.contextual-help-link,
#primary-nav-help-center-medium,
#primary-nav-help-center-large,
#primary-nav-help-center {
  display: none;
}
.contextual-help-link-auth {
  display: block !important;
}
.disabledField {
  background: #eef0f2 !important;
}
.mix-mode-box {
  padding-left: 1.6em;
  padding-bottom: 1.6em;
  padding-right: 1.6em;
}
@media only screen and (min-width: 0px) and (max-width: 769px) {
  .mix-mode-box-small-screen {
    margin-top: 1.6em;
  }
}
.forgot-sso-section .reset-message,
.forgot-reset-section .reset-message {
  padding: 0.7em;
  background-color: #ECF7FC;
}
.forgot-reset-section {
  margin-top: 0.8em;
}
.mixed-mode-section {
  background-color: #ECF7FC !important;
}
.mixed-mode-section div {
  padding: 0.8em;
}
.mixed-mode-section .sso-account-container span {
  padding-left: 0.5em;
}
.mix-mode-message {
  height: 50px;
}
.mixed-mode-container {
  margin-bottom: 0.92em;
  font-size: 0.9em;
}
.forgot-OR-section {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
#sso_reset_btn .btn-label {
  color: #1565b1;
}
.backgroundWhite {
  background: #ffffff !important;
}
.sso-login-link {
  font-size: 1em !important;
  cursor: pointer;
}
.sso-login-container {
  padding-top: 0 !important;
}
.user-name-container {
  margin-bottom: 0.46em !important;
}
.authLoginSubmit {
  margin-top: 1.7rem !important;
}
#error-message {
  display: inline;
}
#email {
  color: #0e1a23;
  display: inline-block;
  margin-bottom: 0.8em;
}
.insurance-icon {
  width: 64px;
  height: 64px;
  margin-top: -8px;
}
.sub-heading {
  color: #556976;
}
#forgot-text-seperator {
  background: #FFFFFF;
  border: 1px solid #c1c8cd;
  box-sizing: border-box;
  border-radius: 8px;
}
#security-question-heading {
  color: #152835;
}
#security-question-heading h3 {
  font-weight: 500 !important;
}
#security-question-button {
  margin-bottom: 2.1em;
}
.forgot-page-heading,
.login-page-heading,
.reset-page-heading {
  background: #ffffff;
  border-radius: 2px;
  color: #0e1a23;
  font-size: 1.5em;
  line-height: 1.7em;
  font-weight: 500;
  margin-bottom: 1.3em;
}
.flex-container {
  display: flex;
  flex-direction: row;
}
.secondary-message {
  display: inline-block;
  margin-bottom: 0.4em;
  color: #303f49;
  font-size: 0.75em;
}
.rowDirection {
  flex-direction: row;
}
.successTitle {
  color: #0e1a23;
  font-weight: 500;
  font-size: 1.6em;
  margin-bottom: 6px;
}
.successIcon {
  height: 54px;
  width: 54px;
  background-color: #178143;
  border-radius: 50%;
}
.flexWrapNo {
  flex-wrap: nowrap;
}
.flex-container {
  flex-direction: row;
  display: flex;
}
.flex-item-mfa-subHeading {
  flex-grow: 1;
}
#mfa-container {
  min-height: 400px;
}
.message-heading {
  font-size: 0.88em;
  color: #303f49;
}
.security-question-button {
  margin-top: 2em;
  font-size: 0.93em;
  line-height: 1.2em;
}
.spaceDiv {
  height: 44px;
}
.new-password-policy {
  box-sizing: border-box;
  border-radius: 0.5em;
  padding: 2rem;
  position: absolute;
  left: 450px;
  height: 23.5em;
  width: 19.88em;
  bottom: 1.6rem;
  background-color: white;
}
.mobile-password-policy {
  background-color: #F5F6FA;
  height: 13.88em;
  width: 100%;
}
#pswd-policy-heading {
  width: 88%;
  font-size: 1.125em;
  line-height: 1.3125em;
  color: #354855;
  margin-bottom: 1.5em;
}
#password-recovery-heading {
  color: #0e1a23;
  margin-left: 1em;
  text-align: left;
  font-size: 1.5em;
  margin-top: 1.25em;
}
.lightbulb-icon {
  width: 4em;
  height: 4em;
  margin-bottom: 1.125em;
}
.reset-insurance-icon {
  width: 4em;
  height: 4em;
  margin-top: 1.25em;
  margin-right: 0.625em;
}
.policyContainer:after,
.policyContainer:before {
  content: '';
  display: block;
  position: absolute;
  left: -22px;
  width: 0;
  height: 0;
  border-style: solid;
}
.policyContainer:after {
  top: 10px;
  border-color: transparent  transparent transparent  transparent;
  border-width: 10px;
}
.policyContainer:before {
  top: 7em;
  border-color: transparent white  transparent transparent;
  border-width: 11px;
}
.relativeContent {
  position: relative;
}
#userName {
  background-color: white !important;
}
#forgot-link {
  margin-top: 32px;
}
.pswd-policy {
  line-height: 1.5em;
  font-size: 1em;
  width: 75%;
}
.required {
  color: #c6261e;
}
#pswd-validate {
  background-color: #F5F6FA !important;
  margin-top: 1em;
  padding: 1em;
  margin-bottom: 1em;
  border: 1em;
}
#password-use-num {
  margin-top: 2.6em;
}
#password-use-uppercase {
  padding-top: 0.5em;
}
.mobile-policy-list {
  padding: 0.75em 0 0.75em 0;
}
.popup-header i {
  line-height: 34px;
  font-size: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  color: #006fcf;
}
.popup-header p {
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.375em;
  margin-bottom: 0.5em;
}
.popup-body {
  margin: 1em 0 1em 0;
}
.popup-date-picker {
  margin-top: 1em;
}
.popup-footer-btn {
  margin-right: 1em;
}
.update-mobile-text {
  margin-top: 2.1em;
  margin-bottom: 1.2em;
}
.phone-field {
  width: 300px;
  margin-bottom: 2em;
}
.footer-btn-right {
  justify-content: flex-end !important;
}
.flex-grow {
  flex-grow: 1;
}
.reset-mfa-success-msg {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.45;
  width: 10.6em;
  color: #0e1a23;
  text-align: left;
}
.reset-mfa-success-icon {
  height: 3.38em;
  width: 3.38em;
  color: white;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #178143;
}
.reset-mfa-success-icon .icon {
  font-size: 1.5em;
}
.reset-page-heading-success {
  font-size: 1em;
}
.hiddenUserName {
  height: 0;
  width: 0;
  position: absolute;
  border: none;
}
#language-switcher-title {
  white-space: nowrap;
}
.date-of-birth {
  margin-top: 1.5625em;
}
generic-message-heading {
  list-style-type: circle;
  font-size: 0.88em;
  display: inline-block;
  margin-bottom: 0.4em;
  color: #303f49;
}
generic-message-heading ::marker {
  content: initial;
}
